// http://ionicframework.com/docs/theming/
@import "@ionic/angular/css/core.css";
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";

@import "@ionic/angular/css/padding.css";
@import "@ionic/angular/css/float-elements.css";
@import "@ionic/angular/css/text-alignment.css";
@import "@ionic/angular/css/text-transformation.css";
@import "@ionic/angular/css/flex-utils.css";

/* Import flag-icons, and set paths */
$flag-icon-css-path: '/assets/flags' !default;
$flag-icon-rect-path: '/4x3' !default;
$flag-icon-square-path: '/1x1' !default;
@import "flag-icon-css/sass/flag-icon";

/* Custom imports */
@import "./assets/fonts/checkproof/style.css";
@import "./assets/fonts/fontawesome/css/all.css";
@import "./theme/app";
@import "./theme/components/card";
@import "./theme/components/modal";
@import "./theme/components/list";
@import "./theme/components/form";
@import "./theme/components/check";
@import "./theme/components/viewer";
@import "./app/components/collapsable/collapsable.component";
@import "./app/components/button/button.component";
@import "./app/components/checkbox/checkbox.component";
@import "./app/components/segment/segment.component";
@import "./app/components/segment/segment-button/segment-button.component";
@import "./app/components/home-screen/home-screen.component";
@import "./app/components/select-dropdown/select-dropdown.component";
@import 'simple-keyboard/build/css/index.css';


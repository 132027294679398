ion-popover {

  &.popover-modal {
    --backdrop-opacity: var(--ion-backdrop-opacity, 0.4);
    --width: calc(100% - 40px);
    --max-width: calc(500px - var(--ion-safe-area-left) - var(--ion-safe-area-right));
    --box-shadow: var(--app-box-shadow);

    &::part(arrow) {
      display: none;
    }

    &::part(content) {
      top: calc(20px + var(--ion-safe-area-top)) !important;
      left: 50% !important;
      padding: 10px;
      transform: translate(-50%, 0);
    }

    .modal-header {
      text-align: center;
      position: relative;
      min-height: 18px;

      i {
        position: absolute;
        right: -10px;
        top: -10px;
        padding: 12px 10px 15px 20px;
      }
    }

    .object-select-next {
      position: relative;
      padding: 7px 10px;
    }
  }

  &.popover-modal-vertical-center {
    &::part(content) {
      top: 50% !important;
      transform: translate(-50%, -50%);
    }
  }

  &.popover-modal-no-padding {
    &::part(content) {
      padding: 0;
    }
  }

  &.popover-modal-confirmation-warning {
    &::part(content) {
      padding: 0;
      border: 4px solid var(--app-color-danger);
    }
  }
}

.modal-fullscreen .modal-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
}


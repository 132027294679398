app-segment {
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  &.app-segment-disabled {
    opacity: .5;
  }

  &.branded {
    app-segment-button {
      border: 1px solid var(--app-default-branding-background-color);

      &[checked] {
        background-color: var(--app-default-branding-background-color);
        color: var(--app-default-branding-text-color);
      }
    }
  }
}

:host {
  display: block;
}

.select-dropdown {
  text-align: left;
  touch-action: manipulation;

  display: block;
  overflow: hidden;
  position: relative;
  background: #fff;
  border: 1px solid var(--app-color-light);
  border-radius: var(--app-input-border-radius);
  color: var(--app-text-color);

  &.sd-select-single {
    .sd-select-container {
      .sd-value-container {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: calc(100% - 30px);

        &.sd-show-overflow {
          white-space: normal;
          overflow: visible;
          text-overflow: inherit;
        }

        .sd-value {
          margin: 5px 0;
        }
      }
    }
  }

  &.sd-select-multiple {
    .sd-select-container {
      &.sd-has-value {
        padding-bottom: 0;
      }

      .sd-value-container {
        max-width: calc(100% - 30px);

        .sd-values-list {
          max-width: 100%;
        }

        .sd-value {
          min-width: 0;
          max-width: 100%;
        }
      }
    }

    .sd-value {
      --border-radius: 27.5px;
      --icon-height: 26px;

      display: inline-block;
      position: relative;
      color: var(--app-text-color);
      font-size: var(--app-text-size-m);
      margin: 0 10px 10px 0;
      outline: none;
      height: 30px;
      border-radius: var(--border-radius);
      box-shadow: var(--app-box-shadow);
      background-color: #ffffff;
      padding: 2px;

      .sd-value-inner {
        padding-left: var(--icon-height);
        padding-right: 10px;
        height: 100%;
        width: 100%;
        line-height: var(--icon-height);
        border-radius: var(--border-radius);
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;
        touch-action: manipulation;

        i {
          left: 2px;
          display: block;
          position: absolute;
          top: 2px;
          width: var(--icon-height);
          height: var(--icon-height);
          line-height: var(--icon-height);
          background-color: var(--app-icon-background-gray);
          border-radius: 100%;
          text-align: center;
          color: var(--app-color-danger);
        }

        span {
          padding-left: 10px;
          padding-right: 2px;
        }
      }
    }

    .ng-clear-wrapper {
      display: none;
    }
  }

  &.sd-select-disabled {
    .sd-select-container {
      .sd-value,
      .sd-placeholder,
      .sd-icon {
        color: var(--app-color-disabled);
      }
    }
  }

  .sd-select-container {
    padding: 10px;
    align-items: center;
    cursor: default;
    display: flex;
    outline: 0;
    overflow: hidden;
    position: relative;
    width: 100%;

    &.sd-select-opened {
      .sd-arrow-wrapper {
        transform: translateY(-50%) rotateX(180deg);
      }
    }

    .sd-value-container {
      display: flex;
      flex: 1;
    }

    .sd-placeholder {
      margin: 5px 0;
    }
  }

  .sd-icon {
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: right;
  }

  .sd-arrow-wrapper {
    position: absolute;
    top: 50%;
    right: 10px;

    transform: translateY(-50%) rotateX(0deg);
    transition: transform 0.2s ease-in-out;

    .sd-arrow {
      font-family: var(--app-fa-font-family);
      font-weight: 400;
      width: auto;
      height: auto;

      &:before {
        content: '\f107';
      }
    }
  }

  app-select-dropdown-panel {
    position: relative;

    .scroll-host {
      overflow: hidden;
      overflow-y: auto;
      position: relative;
      display: block;
      -webkit-overflow-scrolling: touch;
    }

    .sd-dropdown-panel-items {
      max-height: 240px;
      border-top: 1px solid var(--app-background-color);

      .sd-option {
        box-sizing: border-box;
        cursor: pointer;
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        position: relative;
        padding: var(--app-padding);
        border-bottom: 1px solid var(--app-label-background-gray);

        &.sd-option-selected {
          background-color: #f6f6f7;
        }

        &.sd-show-overflow {
          white-space: normal;
          overflow: visible;
          text-overflow: inherit;
        }

        &:last-child {
          border-bottom: none;
        }
      }

      .sd-option-subgroup {
        position: relative;
        padding: var(--app-padding);
        background-color: var(--app-label-background-gray);
        text-align: center;
        font-size: var(--app-text-size-m);
        line-height: 1;
        color: var(--app-text-color);
      }
    }
  }

  .select-option-favorite {
    position: relative;
    padding-right: 20px;

    & > div {
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .action-make-favorite {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
    }
  }
}

.select-children {
  padding-left: 10px;
  padding-top: 10px;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: -6px;
    left: 0;
    height: 43px;
    width: 10px;
    border-left: 1px solid #e3e3e3;
    border-bottom: 1px solid #e3e3e3;
    border-bottom-left-radius: 6px;
  }
}

:host {
  width: 100%;
}
.top-container {
  position: relative;
  padding: 20px 0 40px 0;
  margin-bottom: 20px;
  width: 100%;
  background-image: url('/assets/images/loginscreen_bg.png');
  background-repeat: repeat-x;
  background-size: 375px;
  background-position-y: calc(100% + 15px);
  background-color: var(--app-branding-background-color);
  color: var(--app-branding-text-color);
  overflow: hidden;

  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 140%;
    height: 150%;
    border-radius: 100%;
    box-shadow: 0 300px 0 300px var(--app-background-color);
    transform: translateX(-50%);
  }

  .center {
    text-align: center;
  }

  .username {
    font-size: var(--app-text-size-m);
    line-height: 1.1em;
  }

  .companyname {
    font-size: var(--app-text-size-m);
    line-height: 1em;
    margin-top: 2px;
  }

  app-offline-banner {
    margin: 20px 0;
  }

  .new-button {
    border: 1px solid var(--app-text-color);

    i.button-icon {
      font-size: var(--app-text-size-l);
    }
  }
}

.dashboard-actions {
  padding: 5px;
  overflow: hidden;

  .card-col {
    width: 50%;
    float: left;
    padding: 5px;
    min-height: 128px;
    max-height: 128px;
    height: 128px;
  }

  .card-col:nth-child(odd):last-child {
    width: 100%;

    .card {
      height: auto;
      min-height: auto;
      max-height: initial;
      padding-top: 10px;
      padding-bottom: 10px;

      i {
        display: inline-block;
        font-size: var(--app-text-size-l);
      }

      .action-title {
        margin-left: 0 auto;
      }
    }
  }

  .card {
    padding-top: 20px;
    padding-bottom: 20px;
    min-height: 118px;
    max-height: 118px;
    height: 118px;
    .actions-icon {
      display: flex;
      justify-content: center;
      i {
        width: 40px;
        height: 42px;
        line-height: 42px;
        font-size: var(--app-text-size-xl);
      }
    }
    .action-title-horizontal {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: center;
      align-items: center;
      span {
        margin-left: 10px;
      }
    }

    .action-title {
      height: 36px;
      display: flex;
      justify-content: center;
      align-items: flex-end;
    }
  }
}

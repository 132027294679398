.collapse-trigger {
  position: relative;

  .collapse-trigger-icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translate(0, -50%) rotateX(0deg);
    transition: transform 0.2s ease-in-out;
  }

  &.collapse-trigger-open {
    .collapse-trigger-icon {
      transform: translate(0, -50%) rotateX(180deg);
    }
  }
}

.worktimes-history-page-week,
.worktimes-history-page-day {
  .collapse-trigger .collapse-trigger-icon {
    transform: translate(0, -175%) rotateX(0deg) !important;
  }

  .collapse-trigger-open .collapse-trigger-icon {
    transform: translate(0, -175%) rotateX(180deg) !important;
  }
}

@import "./home-tab/home-tab.component";

:host(.tabs-count-two) {
  .tabs-container {
    &:after {
      width: 50%;
    }

    &.tab-active-1,
    &.tab-active-2 {
      &:after {
        left: 50%;
      }
    }
  }
}

.screen-hidden {
  content-visibility: hidden;
  display: none;
}

.tabs-container {
  position: relative;
  display: flex;
  background-color: var(--app-branding-background-color);

  &:after {
    content: '';
    height: 1px;
    background-color: var(--app-branding-text-color);
    width: 33%;
    position: absolute;
    left: 0;
    bottom: 0;

    transition: left 0.3s ease-in-out;
  }

  &.tab-active-1 {
    &:after {
      left: 33%;
    }
  }
  &.tab-active-2 {
    &:after {
      left: 67%;
    }
  }

  & > a {
    color: var(--app-branding-text-color);
    height: 40px;
    line-height: 25px;
    padding: 5px 0;
    flex: 1;
    text-align: center;
    transition: border-bottom-color 0.5s ease-in-out;

    i {
      font-size: 23px;
      vertical-align: middle;
    }

    .tab-label {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
    }
  }
}

:host ::ng-deep {
  .tabs-container,
  .subtabs-switcher-container {
    .badge {
      position: relative;
      font-size: 13px;
      padding: 2px 5px;
      margin-left: 3px;
      border-radius: 8px;
      background-color: var(--app-branding-text-color);
      color: var(--app-branding-text-color-negative);
      line-height: 13px;
      min-width: 18px;
      min-height: 17px;

      ion-spinner {
        color: var(--app-branding-text-color-negative);
        position: absolute;
        top: -6px;
        left: -5px;
        transform: scale(0.5);
      }
    }
  }

  .subtabs-switcher-container {
    .badge {
      top: -1px;
      left: -3px;
      font-size: var(--app-text-size-s);
      display: inline-block;
    }
  }
}

.swiper-slide {
  font-size: var(--app-text-size-m);
  max-height: 100vh;

  &.swiper-slide-active {
    max-height: initial;
  }
}

.swiper-containers {
  width: 100%;
  height: 100%;
  .swiper-slides {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      max-width: 100%;
      max-height: 100%;
      ion-img, img {
        display: block;
        max-width: 100%;
        max-height: 80%;
        width: 100%;
        height: 100%;
      }
      app-video-player {
        display: flex;
        max-width: 100%;
        max-height: 100%;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
      }
    }
}

swiper-container::part(bullet) {
  background-color: #808080FF;
  opacity: 1;
}


.tab-content {
  text-align: left;
  width: 100%;

  .tab-content-header {
    font-size: var(--app-text-size-m);
    font-weight: normal;
    padding: 20px 0;
    text-align: center;
  }

  .subtabs-switcher-container {
    padding: 0 10px;
    margin-bottom: 20px;
  }

  .tab-filter-container {
    padding: 0 10px 20px 10px;
    border-bottom: 1px solid var(--app-color-light);
    margin-bottom: 10px;
  }
}

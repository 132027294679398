.form-row {
  display: flex;
  flex-wrap: wrap;

  .form-col {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
    padding: 0 5px;

    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
    }
  }
}

.form-section {
  padding: var(--app-padding);
}

.form-section-divider {
  border-top: 1px solid #dddddd;
}

.input {
  text-align: left;
  height: 50px;
  border-radius: var(--app-input-border-radius);
  font-size: 15px;
  background: #ffffff;
  border: 1px solid #dddddd;

  line-height: 28px;
  box-shadow: none;
  outline: none;
  -webkit-appearance: none;
  width: 100%;

  &.invalid-input {
    border-color: var(--app-color-danger);
  }

  &::placeholder {
    color: var(--app-color-light-shade);
  }

  &.disabled {
    background-color: var(--app-background-color);
  }
}

.input-icon {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.input-with-button {
  display: flex;
  flex-wrap: nowrap;

  app-button {
    margin-left: var(--app-padding);
  }
}

.input-sub-caption {
  font-size: var(--app-text-size-s);
  text-align: right;
  padding-right: var(--app-padding);
  color: var(--app-text-color-secondary);
}

.form-group {
  margin-bottom: var(--app-padding);
}

.form-control {
  position: relative;
}

.input-required:before {
  content: ' *';
  color: var(--app-color-danger);
}

.input-has-error {
  border-color: var(--app-color-danger) !important;
}

.input-group {
  border: 1px solid var(--app-color-light);
  border-radius: var(--app-input-border-radius);
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  align-items: stretch;
  width: 100%;
  overflow: hidden;

  &.input-group-has-error {
    border-color: var(--app-color-danger);
  }

  > .form-control {
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0;

    &:not(:first-child) {
      > .input {
        border-left: 1px solid var(--app-color-light);
      }
    }
  }

  .input {
    border: none;
    border-radius: 0;
    height: 48px;
  }

  &.input-group-small {
    .input {
      height: 31px;
    }
  }
}

.input-group-prepend,
.input-group-append {
  display: flex;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0 5px;

  text-align: center;
  white-space: nowrap;
  line-height: 48px;

  background-color: var(--app-color-light);
  color: var(--app-color-light-shade);
}

.checkbox-icon {
  position: relative;
  width: var(--app-text-size-m);
  height: var(--app-text-size-m);
  border-radius: 4px;
  border: 1px solid var(--app-border-color);
  box-shadow: inset var(--app-box-shadow);
  background-color: #ffffff;
  margin-right: 10px;
  text-align: center;
  line-height: 18px;
}

.form-group-sub-input-panel {
  padding: 0px 0px 0px var(--app-padding);
  margin: 0px 0px 0px var(--app-margin);
  border-left: var(--app-border-color-grey) 1px solid;

  .input-label-sub-title {
    font-size: var(--app-text-size-s);
  }
}

ion-textarea {
  border-radius: var(--app-input-border-radius);
  border: 1px solid var(--app-color-light);

  &.sc-ion-textarea-md-h {
    margin-top: 0;
  }

  .textarea-wrapper {
    .native-textarea {
      --border-radius: var(--app-input-border-radius);
      --padding-start: 12px;
      --padding-end: 12px;
      --padding-top: 15px;
      --padding-bottom: 15px;

      font-size: 15px;
      line-height: 19px;
    }
    .native-textarea:not([disabled]) {
      background: #fff;
      border-radius: var(--app-input-border-radius);
      padding: 15px 12px;
    }
  }
}

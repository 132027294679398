.card {
  display: block;
  background: #fff;
  border-radius: 8px;
  box-shadow: var(--app-box-shadow);
  color: var(--app-text-color);
  text-decoration: none;
  position: relative;

  &.input-has-error {
    box-shadow: var(--app-box-shadow), inset 0 0 0 1px var(--app-color-danger);

    .card-footer {
      border-top-color: var(--app-color-danger);
    }
  }

  &.card-inactive {
    background-color: transparent;
    border: solid 1px #dddddd;
    box-shadow: none;
  }

  &.card-with-badges {
    overflow: hidden; // should be manually set where its needed, otherwise it breaks deviations card in object info modal
  }

  .card-icon {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(0, -50%);
    padding: 20px 10px 20px 20px;
  }

  p {
    margin: 5px 0;
  }

  .card-title-header {
    color: var(--app-text-color-secondary);
    line-height: 1.3;
    margin-bottom: 4px;
  }

  .card-title-footer {
    font-size: var(--app-text-size-m);
    line-height: 1.3;
    margin-top: 4px;
  }

  .card-footer {
    border-top: 1px solid var(--app-background-color);
  }

  .card-header {
    border-bottom: 1px solid #DDDDDD;
    line-height: 1.3;
    padding: 10px;
  }

  .card-content-with-background {
    background-color: #F6F6F7;
    border-radius: 8px;
  }

  .card-delete-icon, .card-edit-icon {
    position: absolute;
    line-height: 1.3;
    right: 10px;
    z-index: 1;
  }

  .card-with-border {
    border: 1px solid #DDDDDD;
  }

  .card-title-flex {
    display: flex;

    .card-col-6 {
      width:60%
    }

    .card-col-4 {
      width: 40%;
      text-align: end;
      padding-right: 20px;
    }

    .card-col-4-extra-padding {
      width: 40%;
      text-align: end;
      padding-right: 30px;
      font-weight: bold;
    }
  }
}

.card-content {
  position: relative;
  padding: var(--app-padding);

  .card-content-full-space {
    margin: calc(-1 * var(--app-padding));
  }

  .title {
    font-size: var(--app-text-size-m);
  }

  .list-title {
    margin-bottom: 2px;
  }
}

.check-card-content {
  padding: 0;
  .card-inactive {
    background-color: transparent;
  }

  .check-card-grid {
    padding: 0;
    border-bottom: var(--check-card-inside-border-color);
    ion-row {
      ion-col {
        padding: var(--app-padding);
        .name {
          font-weight: 700;
          font-size: var(--app-text-size-m);
        }
      }
      .icon-container {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;
        .delete-icon {
          color: var(--app-color-danger);
        }
      }
      .line-right {
        border-left: var(--check-card-inside-border-color);
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-content: center;
        justify-content: center;
      }
    }
  }

  .check-card-footer-container {
    background-color: var(--check-card-background-bottom);
    border-bottom-left-radius: var(--app-input-border-radius);
    border-bottom-right-radius: var(--app-input-border-radius);
    .check-card-footer {
      &:first-child {
        padding-top: var(--app-padding);
      }
      &.progress {
        border-top: 1px solid #DDDDDD;
        padding-top: var(--app-padding);
      }
      padding-left: var(--app-padding);
      padding-right: var(--app-padding);
      padding-bottom: var(--app-padding);
    }
    &.check-card-footer-container-inactive {
      background-color: transparent;
    }
  }
}

.card-gray-background {
  background-color: var(--app-background-color);
}

.card-images-list {
  padding-top: var(--app-padding);
  padding-left: var(--app-padding);
}

.card-sub-content {
  border-top: 1px solid var(--app-background-color);
}

.card-label, .input-label {
  padding: 0 0 5px var(--app-padding);
  font-size: var(--app-text-size-m);
}

.card-list-label {
  &:first-child {
    padding-top: 0;
  }

  padding: var(--app-padding);
  font-size: var(--app-text-size-l);
}

.card.task {
  margin: var(--app-margin-l) 0;
}

.card-list {
  &.card-list-shadow {
    box-shadow: var(--app-box-shadow);
  }

  &.card-list-md {
    .card-item {
      padding: 20px 10px;
    }
  }

  &.card-list-item-shadows {
    .card-item {
      box-shadow: inset 0 7px 9px -10px rgba(0,0,0,0.4);

      &:first-child {
        box-shadow: none;
      }
    }
  }

  .card-item {
    position: relative;
    padding: var(--app-padding);
    border-bottom: 1px solid var(--app-background-color);

    &.card-item-no-padding {
      padding: 0;
    }

    &:last-child {
      border-bottom: none;
    }
  }

  .card-item-subgroup {
    position: relative;
    padding: var(--app-padding);
    background-color: var(--app-background-color);
    text-align: center;
    font-size: var(--app-text-size-s);
    line-height: 1;
  }

  .card-item-content {
    padding: var(--app-padding);

    &.card-item-subcontent {
      background-color: var(--app-background-color);
    }
  }
}

.card-badge {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  padding: 0 4px;
  display: flex;
  align-items: center;
  justify-content: center;

  &.badge-danger {
    background-color: var(--app-color-danger);
    color: #fff;
  }
  &.badge-success {
    background-color: var(--app-color-success);
    color: #fff;
  }
  &.badge-warning {
    background-color: var(--app-color-warning);
  }
  &.badge-inactive {
    background-color: var(--app-background-color);
  }
  &.badge-not-applicable {
    background-color: var(--app-color-not-applicable);
    padding: 0;

    span {
      transform: rotate(90deg);
    }
  }

  &.badge-rfid-check {
    background-color: #444;
    color: #FFF;
    padding: 0 3px;
  }
}

.card-content-group {
  display: flex;
  flex-wrap: nowrap;

  .card-content-prefix {
    color: var(--app-color-light-shade);
    border-right: 1px solid #ddd;
  }
}

.card-progress-bar {
  position: absolute;
  left: 0;
  top: 0;
  height: 5px;
  background-color: #9fb2b5;
}

.card-list-margin {
  margin-bottom: 20px;
}

.card-deviations-list {
  .card {
    box-shadow: none;
    border-bottom: 1px solid var(--app-border-color);
  }

  app-card-deviation:last-child {
    .card {
      border-bottom: none;
    }
  }
}

.card-has-errors {
  border: 1px solid var(--app-color-danger);

  .card-sub-content {
    border-color: var(--app-color-danger);
  }

  .card-list {
    .card-item {
      border-color: var(--app-color-danger);
    }
  }
}

app-card-worktime + app-card-worktime {
  margin-top: 10px;
}

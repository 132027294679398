@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src:  url('/assets/fonts/Roboto-Light.ttf') format('woff');
  //unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url('/assets/fonts/Roboto-Regular.ttf') format('woff');
  //unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: url('/assets/fonts/Roboto-Medium.ttf') format('woff');
  //unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: url('/assets/fonts/Roboto-Bold.ttf') format('woff');
  //unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}

.validation-errors-inline {
  font-size: 12px;
  color: var(--app-color-danger);
  margin-top: 5px;
  text-align: left;
  text-indent: 7px;
}

.invalidInput {
  border-color: var(--app-color-danger) !important;
}

body {
  font-family: var(--app-font-family);
  font-size: var(--app-text-size-m);
  line-height: var(--app-line-height);
}

p {
  margin: var(--app-margin) 0;
}

a {
  color: var(--app-text-color);
  text-decoration: none;
}

.text-small {
  font-size: var(--app-text-size-s);
}

.text-medium {
  font-size: var(--app-text-size-m);
}

.text-large {
  font-size: var(--app-text-size-m);
  font-weight: bold;
}

.text-xlarge {
  font-size: var(--app-text-size-l);
}

.text-secondary {
  color: var(--app-text-color-secondary);
}

.text-link {
  color: var(--app-color-link);
}

.text-ellipsis {
  max-width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.text-line-through {
  text-decoration: line-through;
}

.text-underline {
  text-decoration: underline;
}

.text-semi-bold {
  font-weight: 500;
}

.text-forced-brakes {
  white-space: pre-line;
}

.compensate-margins {
  margin: calc(-1 * var(--app-padding));
}
.compensate-horizontal-margins {
  margin-left: calc(-1 * var(--app-padding));
  margin-right: calc(-1 * var(--app-padding));
}

.color-danger {
  color: var(--app-color-danger);
}
.color-success {
  color: var(--app-color-success);
}
.color-warning {
  color: var(--app-color-warning);
}

.color-marker {
  color: #de4034;
}

.status-bar-background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: var(--ion-safe-area-top);
  z-index: 30;
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
}

.action-sheet-icon-fa {
  span:before {
    font-family: 'Font Awesome 5 Pro';
    font-weight: 400;
    content: '';
    margin-right: 10px;
  }

  &.service {
    span:before {
      content: '\f552';
    }
  }
  &.incident {
    span:before {
      content: '\f31a';
    }
  }
  &.deviation {
    span:before {
      content: '\f0ad';
    }
  }
  &.check-perform {
    span:before {
      content: '\f46d';
    }
  }
  &.refill {
    span:before {
      content: '\f043';
    }
  }
  &.check {
    span:before {
      content: '\f00c';
    }
  }

  &.cabinet-filing {
    span:before {
      content: '\f64b';
    }
  }

  &.times {
    span:before {
      content: '\f00d';
    }
  }

  &.undo {
    span:before {
      content: '\f0e2';
    }
  }

  &.rfid {
    span:before {
      content: '\f1eb';
      display: inline-block;
      -webkit-transform:rotateZ(45deg);
      transform: rotateZ(45deg);
    }
  }
}
.action-sheet-icon-checkproof {
  span:before {
    font-family: 'CheckProof-Icons';
    font-weight: 400;
    content: '';
    margin-right: 10px;
  }

  &.unitsrounds {
    span:before {
      content: '\e904';
    }
  }
}

.app-init-retry {
  text-align: center;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
}

.navTop {
  --background: #FED700;
  --color: #000;

  i {
    color: #000;
  }
}

.object-status {
  display: inline-block;
  width: 5px;
  height: 5px;
  border-radius: 100%;
  margin: 0 5px 3px 5px;

  &.object-status-in-use {
    background-color: var(--app-color-success);
  }
  &.object-status-not-in-use {
    background-color: var(--app-color-danger);
  }
}

.tab-content-inactive {
  display: none;
}

.gray-section-label {
  background-color: var(--app-label-background-gray);
  width: 100%;
  height: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.page-title {
  padding-left: 20px;
  padding-right: 20px;

  h1 {
    font-size: var(--app-text-size-l);
    line-height: var(--app-line-height-xl);
    padding: 0;
    margin: 0;

    .object-status {
      margin-bottom: 5px;
    }
  }
}

.images-list {
  overflow: hidden;

  > app-image,
  > .image-wrapper,
  > app-asset
  {
    margin-right: 13px;
    margin-bottom: var(--app-padding);
    float: left;

    ion-img {
      float: left;
    }
  }
}

.app-border-top {
  border-top: 1px solid var(--app-border-color);
}

.info-block {
  padding: 10px 20px 0 20px;
  border-top: 1px solid #dddddd;
  border-bottom: 1px solid #dddddd;
}

ion-app.disable-scroll {
  ion-content > .scroll-content {
    overflow: hidden;
  }
}

.no-scroll .scroll-content {
  overflow: hidden;
}

ion-content {
  &.disable-scroll {
    --overflow: hidden;
  }
}

// Fix to make action sheets scrollable on iOS devices
.action-sheet-group.sc-ion-action-sheet-ios {
  overflow: auto;
}

// Adding padding to forst element in action sheets, visable if an action sheet is fullscreen top to bottom
.action-sheet-group.sc-ion-action-sheet-ios:first-child {
  margin-top: calc(30px + var(--ion-safe-area-top));
}

h1 i.title-favorite-icon {
  padding-left: var(--app-padding);
}

#sticked-header {
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 1001;
  padding: 15px 20px;
  background-color: var(--ion-background-color, #fff);
  color: var(--ion-text-color, #000);
  font-size: var(--app-text-size-l);
  margin-top: constant(safe-area-inset-top);
  margin-top: env(safe-area-inset-top);
}

.divider-title {
  font-weight: 500;
}

.progress-bar {
  height: 5px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: var(--app-box-shadow);
  overflow: hidden;
  position: relative;

  > div {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    background-color: #9FB2B5;
  }
}

.control-point-content {
  &.collapsed {
    display: none;
  }

  .inner-wrapper {
    position: relative;

    .card-badge {
      &.badge-danger {
        background-image: linear-gradient(to bottom, var(--app-color-danger), rgba(255, 255, 255, 1));
      }

      &.badge-success {
        background-image: linear-gradient(to bottom, var(--app-color-success), rgba(255, 255, 255, 1));
      }

      &.badge-warning {
        background-image: linear-gradient(to bottom, var(--app-color-warning), rgba(255, 255, 255, 1));
      }

      &.badge-inactive {
        background-image: linear-gradient(to bottom, var(--app-background-color), rgba(255, 255, 255, 1));
      }

      &.badge-not-applicable {
        background-image: linear-gradient(to bottom, var(--app-color-not-applicable), rgba(255, 255, 255, 1));
      }

    }
  }

  .content-section {
    display: block;

    .checkbox-icon {
      display: inline-block;
      box-shadow: inset 0 1px 4px 0 rgba(0, 0, 0, 0.1) !important;
      border: 1px solid var(--app-border-color-dark) !important;
    }

    .radiobutton-icon {
      border: 1px solid var(--app-border-color-dark) !important;
      background-color: var(--ion-color-light-tint) !important;
    }

    .input-group,
    textarea,
    .select-dropdown,
    .input-date {
      border: 1px solid var(--app-border-color-dark) !important;
    }

    .new-signature {
      border: 1px dashed var(--app-border-color-dark);
    }

    &.helptext {
      border-top: 1px solid var(--app-background-color);
      border-bottom: 1px solid var(--app-background-color);

      .card {
        box-shadow: none;
      }
    }

    &.deviations, &.previous-value {
      border-top: 1px solid var(--app-background-color);
      border-bottom: 1px solid var(--app-background-color);
      position: relative;
      top: -1px;
    }

    &.previous-value {
      top: -2px;
    }

    .deviations-section-title {
      background-color: var(--app-label-background-gray);
      color: var(--app-text-color);
    }
  }

  app-image + .content-section {
    border-top: none;
  }

  .sub-content-title {
    margin: 5px 0;
  }

  .sub-content-icon {
    display: inline-block;
    margin-right: 10px;

    > span {
      text-align: center;
    }

    ion-spinner {
      width: 15px;
      display: inline-block;
      height: 15px;
    }
  }

  .deviations-counter {
    font-size: var(--app-text-size-m);
    line-height: 16px;
    height: 22px;
    min-width: 22px;
    border-radius: 22px;
    position: relative;
    top: -2px;
    color: var(--app-color-deviation);
    font-weight: bold;
    display: inline-block;
    padding: 2px;
    border: 1.5px solid var(--app-color-deviation);
  }

  .deviations-section-title {
    text-align: center;
    font-size: var(--app-text-size-s);
    padding: 12px 0;
  }

  .control-point-actions {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    margin-right: -10px;

    &.full-screen-actions {
      flex-wrap: wrap;

      > div {
        flex: auto;
        width: 50%;
        order: 1;

        &.button-na {
          order: 3;
          width: 100%;
          margin-top: 10px;
        }
      }
    }

    > div {
      flex: 1;
      padding-right: 10px;
    }
  }

  .performed-by {
    font-size: var(--app-text-size-s);
    color: var(--app-text-color-secondary);
    padding: 0 10px 10px 10px;
  }
}

.control-points {
  .point-label {
    font-weight: bold;

    .input-required {
      font-weight: normal;
    }
  }
}

.control-point-actions {
  app-button {
    button {
      &.button-square,
      &.button-rectangle {
        height: 65px;
        font-size: var(--app-text-size-m);
      }

      i.button-icon {
        font-size: 27px;
      }
    }
  }
}

.standard-mode {
  .control-points {
    a {
      text-decoration: underline;
      color: var(--app-color-link);
    }

    .point-label {
      margin: 5px 0;
      font-size: var(--app-text-size-m);
      line-height: 1.1em;
    }

    .sub-content-title {
      font-size: var(--app-text-size-m);
    }

    &.list-margins {
      .list-element {
        margin-bottom: var(--app-margin-l);
      }
    }
  }
}

.compact-mode {
  .control-points {
    > div {
      border-bottom: 1px solid var(--app-border-color);

      &:last-child {
        border-bottom: none;
      }
    }
  }
}

.fullscreen-mode {
  .point-label {
    font-weight: bold;
    font-size: var(--app-text-size-l);
    line-height: 28px;
    padding: var(--app-margin) 0;
  }
}

.check-overview {
  .card-badge {
    padding: 0 0 0 5px;
  }

  .list-element {
    border-bottom: 1px solid var(--app-border-color);

    &.invalid {
      app-check-control-point-summary {
        border: 1px solid var(--app-color-danger);
      }
    }

    &:first-child {
      app-check-control-point-summary {
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
      }
    }

    &:last-child {
      border-bottom: none;

      app-check-control-point-summary {
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
      }
    }
  }

  .images-list {
    margin-bottom: -10px;
  }
}

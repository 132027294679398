app-button {
  --height: 50px;
  --icon-height: 46px;
  --button-padding-left: 10px;
  --button-padding-right: 10px;
  --button-radius: 27.5px;
  --button-content-width-offset: 0;

  outline: none;

  &.button-has-error {
    button.button-rounded {
      box-shadow: var(--app-box-shadow), inset 0 0 0 1px var(--app-color-danger);
    }
  }

  &.rfid-btn {
    button.button-rounded {
      text-transform: uppercase;
      &.button-disabled {
        cursor: default;
        opacity: 0.5;
        background: #f5f6f8;
      }
      &.button-spinner {
        border: 2px solid transparent;
        height: 46px;
        transform-style: preserve-3d;
        -webkit-transform-style: preserve-3d;

        .button-inner {
          line-height: 42px;
        }

        &.button-icon-align-left,
        &.button-icon-align-right {
          i.button-icon {
            top: -2px;
            line-height: 42px;
          }
        }

        &.button-icon-align-left {
          i.button-icon {
            left: 0;
          }
        }

        &.button-icon-align-right {
          i.button-icon {
            right: 0;
          }
        }
      }

      &.button-spinner::before {
        content: '';
        position: absolute;
        top: -4px;
        right: -4px;
        bottom: -4px;
        left: -4px;

        transform: translateZ(-1px);

        overflow: hidden;
        border-radius: 29px;

        animation-name: button-animation;
        animation-delay: 0s;
        animation-timing-function: linear;
        animation-duration: 1600ms;
        animation-iteration-count: infinite;
        animation-direction: reverse;
      }
    }
  }

  button {
    position: relative;
    color: var(--app-text-color);
    font-size: var(--app-text-size-m);
    margin: 0;
    outline: none;
    max-width: 100%;

    &.button-simple {
      padding: 0;
      background: transparent;
    }

    &.button-rounded {
      height: var(--height);
      border-radius: var(--button-radius);
      box-shadow: var(--app-box-shadow);
      background-color: #ffffff;
      padding: 2px;

      &.button-background-gradient {
        .button-inner {
          background: linear-gradient(to right, #f6f6f7, #ffffff);
        }
      }

      .button-content {
        display: inline-block;
        padding-left: 15px;
        padding-right: 15px;

        max-width: calc(100vw - 15px - var(--button-content-width-offset));
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      .button-inner {
        padding-left: var(--button-padding-left);
        padding-right: var(--button-padding-right);
        height: 100%;
        width: 100%;
        line-height: var(--icon-height);
        border-radius: var(--button-radius);
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;
      }

      i.button-icon {
        font-size: var(--app-text-size-l);
      }

      &.accent-background {
        background-color: #47AD50;
        color: white;
      }
    }

    &.button-square,
    &.button-rectangle {
      box-shadow: var(--app-box-shadow);
      height: 50px;
      border-radius: 8px;
      background-color: #fff;

      &:active {
        box-shadow: var(--app-box-shadow), inset 0 0 5px rgba(0, 0, 0, 0.3);
      }

      &.button-background-success {
        background-color: var(--app-color-success);
        color: #fff;
      }

      &.button-background-deviation {
        background-color: var(--app-color-deviation);
        color: #fff;
      }

      &.button-background-warning {
        background-color: var(--app-color-warning);
      }

      &.button-background-inactive {
        background-color: var(--app-background-color);
      }

      &.button-background-not-applicable {
        background-color: var(--app-color-not-applicable);
      }
    }

    &.button-square {
      width: 50px;
    }

    &.button-disabled {
      cursor: default;
      opacity: .5;
    }
  }

  &[expand="block"] {
    button {
      width: 100%;

      .button-content {
        padding-left: 2px;
        padding-right: 2px;
      }
    }
  }

  &[size="small"] {
    --height: 30px;
    --icon-height: 26px;
  }

  &[size="large"] {
    --height: 55px;
    --icon-height: 51px;
    --button-padding-left: 20px;
    --button-padding-right: 20px;

    .button-content {
      font-weight: 500;
      font-size: var(--app-text-size-m);
    }

    i.button-icon {
      font-size: 35px;
    }
  }

  &.login-page-button {
    button {
      background-color: var(--app-background-color);
      border: 1px solid var(--app-color-light);
      padding: 0 20px;
      height: 40px;
      font-weight: 500;

      .button-inner {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;
      }
    }

    &.azure button {
      background-color: #fff;
      border: 1px solid #8c8c8c;
      padding: 0 10px;
      color: #5e5e5e;
      font-size: var(--app-text-size-s);

      .button-inner:before {
        content: '';
        background-image: url('/assets/icon/ms-icon.png');
        width: 21px;
        height: 21px;
        margin-right: 10px;
      }
    }

    &.sso button {
      color: #03416A;

      .button-inner:before {
        content: '';
        background-image: url('/assets/icon/sso.png');
        width: 21px;
        height: 21px;
        margin-right: 10px;
      }
    }

    &.forgot button {
      color: #787878;
    }
  }

  .button-icon-align-left {
    --button-padding-left: var(--icon-height);
    --button-padding-right: 10px;
    --button-content-width-offset: var(--icon-height);

    i.button-icon {
      left: 2px;
    }
  }

  .button-icon-align-right {
    --button-padding-left: 10px;
    --button-padding-right: var(--icon-height);
    --button-content-width-offset: var(--icon-height);

    i.button-icon {
      right: 2px;
    }
  }

  .button-icon-align-left,
  .button-icon-align-right {
    i.button-icon {
      display: block;
      position: absolute;
      top: 2px;

      width: var(--icon-height);
      height: var(--icon-height);
      line-height: var(--icon-height);

      &.button-icon-background-circle {
        background-color: var(--app-icon-background-gray);
        border-radius: 100%;
      }
    }
  }

  .button-icon-align-center {
    i.button-icon {
      margin-left: 10px;
    }
  }

  .large-icon {
    font-size: var(--app-text-size-l);
  }

  .accent {
    color: #49ab51;
    border: 1px solid #49ab51;
  }

  &.no-wrap-text-button {
    button {
      .button-inner {
        line-height: 20px;
        .button-content {
          white-space: break-spaces;
          overflow: visible;
        }
      }
    }
  }
}

@keyframes button-animation {
  0%      { background: conic-gradient(from 0deg at 50% 50%, #787878, #FFF); }
  6.25%   { background: conic-gradient(from 22.5deg at 50% 50%, #787878, #FFF); }
  12.5%   { background: conic-gradient(from 45deg at 50% 50%, #787878, #FFF); }
  18.75%  { background: conic-gradient(from 67.5deg at 50% 50%, #787878, #FFF); }
  25%     { background: conic-gradient(from 90deg at 50% 50%, #787878, #FFF); }
  31.25%  { background: conic-gradient(from 112.5deg at 50% 50%, #787878, #FFF); }
  37.5%   { background: conic-gradient(from 135deg at 50% 50%, #787878, #FFF); }
  43.75%  { background: conic-gradient(from 157.5deg at 50% 50%, #787878, #FFF); }
  50%     { background: conic-gradient(from 180deg at 50% 50%, #787878, #FFF); }
  56.25%  { background: conic-gradient(from 202.5deg at 50% 50%, #787878, #FFF); }
  62.5%   { background: conic-gradient(from 225deg at 50% 50%, #787878, #FFF); }
  68.75%  { background: conic-gradient(from 247.5deg at 50% 50%, #787878, #FFF); }
  75%     { background: conic-gradient(from 270deg at 50% 50%, #787878, #FFF); }
  81.25%  { background: conic-gradient(from 292.5deg at 50% 50%, #787878, #FFF); }
  87.5%   { background: conic-gradient(from 335deg at 50% 50%, #787878, #FFF); }
  93.75%  { background: conic-gradient(from 357.5deg at 50% 50%, #787878, #FFF); }
  100%    { background: conic-gradient(from 0deg at 50% 50%, #787878, #FFF); }
}


.media-viewer {
  display: flex;
  flex-direction: column;
  height: 100%;

  .imageWrapper {
    margin-top: var(--ion-safe-area-top);
    max-height: calc(100% - 53px - var(--ion-safe-area-top) - var(--ion-safe-area-bottom));
    height: 100%;
    position: relative;

    img {
      display: block;
      max-height: 100%;
      max-width: 100%;
      margin: 0 auto;
    }
  }

  @media only screen and (min-width:768px) and (min-height:600px) {
    .imageWrapper {
      margin-top: 0;
    }
  }

  .toolbar {
    margin-bottom: env(safe-area-inset-bottom);
    position: relative;

    button {
      outline-style: none;
      background: transparent;
      padding: 0;
      margin: 0;
    }

    .buttons {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      padding: 10px 0;
      background-color: #f6f6f7;
      position: relative;

      i {
        position: relative;
        width: 33px;
        height: 33px;
        line-height: 33px;
        border-radius: 100%;
        text-align: center;
        font-size: var(--app-text-size-m);
        background-color: #fff;
        color: #fff;
        box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);

        &.fa-times {
          font-size: 20px;
          font-weight: 300;
          background-color: #de4034;
        }

        &.fa-undo {
          color: #444;
        }

        &.fa-eraser {
          color: #de4034;
        }

        &.fa-trash {
          color: #de4034;
        }

        &.fa-palette {
          &.default {
            background-color: #fff;
            color: #4a90e2;
          }

          &.black {
            background-color: #000;
          }

          &.white {
            background-color: #fff;
            color: #000;
          }

          &.red {
            background-color: #d0021b;
          }

          &.green {
            background-color: #7ed321;
          }

          &.blue {
            background-color: #4a90e2;
          }
        }

        &.fa-check {
          font-size: 20px;
          background-color: #49ab51;
        }
      }
    }
  }
}

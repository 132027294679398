// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  /** primary **/
  --ion-color-primary: #3880ff;
  --ion-color-primary-rgb: 56,128,255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255,255,255;
  --ion-color-primary-shade: #3171e0;
  --ion-color-primary-tint: #4c8dff;

  /** secondary **/
  --ion-color-secondary: #0cd1e8;
  --ion-color-secondary-rgb: 12,209,232;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255,255,255;
  --ion-color-secondary-shade: #0bb8cc;
  --ion-color-secondary-tint: #24d6ea;

  /** tertiary **/
  --ion-color-tertiary: #7044ff;
  --ion-color-tertiary-rgb: 112,68,255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255,255,255;
  --ion-color-tertiary-shade: #633ce0;
  --ion-color-tertiary-tint: #7e57ff;

  /** success **/
  --ion-color-success: #49ab51;
  --ion-color-success-rgb: 16,220,96;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255,255,255;
  --ion-color-success-shade: #409647;
  --ion-color-success-tint: #5bb362;

  /** warning **/
  --ion-color-warning: #ffce00;
  --ion-color-warning-rgb: 255,206,0;
  --ion-color-warning-contrast: #ffffff;
  --ion-color-warning-contrast-rgb: 255,255,255;
  --ion-color-warning-shade: #e0b500;
  --ion-color-warning-tint: #ffd31a;

  /** danger **/
  --ion-color-danger: #de4034;
  --ion-color-danger-rgb: 245,61,61;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255,255,255;
  --ion-color-danger-shade: #c3382e;
  --ion-color-danger-tint: #e15348;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34,34,34;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255,255,255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152,154,162;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255,255,255;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244,244,244;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0,0,0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;


  /** App custom variables **/
  --app-font-family: 'Roboto';
  --app-fa-font-family: 'Font Awesome 5 Pro';
  --app-text-color: #444444;
  --app-text-color-secondary: #747678;
  --app-text-color-light: #ffffff;
  --app-background-color: #e9ebf0;
  --app-border-color: #e4e4e5;
  --app-border-color-dark: #a8a8a8;

  --app-color-danger: #de4034;
  --app-color-success: #49ab51;
  --app-color-warning: #fed700;
  --app-color-deviation: #0C93FF;
  --app-color-link: #ee7930;
  --app-color-disabled: #929292;
  --app-color-not-applicable: #9FB2B5;
  --app-color-light: #dddddd;
  --app-color-light-shade: #acacac;

  --app-text-size-xs: 12px;
  --app-text-size-s: 14px;
  --app-text-size-m: 17px;
  --app-text-size-l: 25px;
  --app-text-size-xl: 35px;

  --app-line-height: 22px;
  --app-line-height-xl: 32px;

  --app-margin: 10px;
  --app-margin-s: 5px;
  --app-margin-m: 15px;
  --app-margin-l: 20px;
  --app-padding: 10px;

  --app-icon-background-gray: #f5f5f5;

  --app-default-branding-background-color: #FFD800;
  --app-default-branding-text-color: var(--app-text-color);
  --app-default-branding-text-color-negative: #fff;
  --app-branding-background-color: var(--app-default-branding-background-color);
  --app-branding-text-color: var(--app-default-branding-text-color);
  --app-branding-text-color-negative: var(--app-default-branding-text-color-negative);

  --app-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);

  --app-label-background-gray: #CDCDCD;

  --app-segment-button-active: #444444;

  --app-border-color-grey: #DDDDDD;
  --app-input-border-radius: 8px;

  /** Override Ionic css variables **/
  --ion-text-color: var(--app-text-color);
  --ion-background-color: var(--app-background-color);
  --ion-margin: var(--app-margin);
  --ion-padding: var(--app-padding);

  /** Side menu **/
  --side-menu-background: #FFFFFF;
  --side-menu-item-blue: #0C93FF;
}

ion-content {
  --ion-font-family: var(--app-font-family);
  --padding-bottom: var(--ion-safe-area-bottom);
}

.home-widget-button-visible {
  ion-content {
    --padding-bottom: calc(30px + var(--ion-safe-area-bottom));
  }

  &.offline-banner-visible {
    ion-content {
      --padding-bottom: calc(62px + var(--ion-safe-area-bottom));
    }
  }
}

.ios ion-toast {
  --background: #626263;
  --color: #fff;
  --button-color: #fff;
}

ion-toolbar {
  --background: var(--app-background-color);
}

.task {
  --task-padding: 20px;
  --task-title-text-size: 18px;

  --task-color-blank: #FFFFFF;
  --task-color-warning: var(--app-default-branding-background-color);
  --task-color-danger: #E84646;
  --task-color-inactive: #EAECF1;
  --task-color-inactive-border: #DDDDDD;

  --task-color-check: var(--app-text-color);
  --task-color-deviation: #0C93FF;
  --task-color-service: #FF7200;

  --task-text: #747678;
  --task-text-medium: #787878;
  --task-text-title: #444444;
  --task-text-dark: #292929;
  --task-text-accent-dark: #000000;

  --task-color-background-gradient-major: #F2F2F2;
  --task-color-background-inactive-gradient-major: #DDDDDD;
  --task-breakline: #DDDDDD;
}

.worktimes-history {
  --worktimes-history-padding: 20px;
  --worktimes-history-title-text-size: 18px;
  --worktimes-history-breakline: #DDDDDD;
  --worktimes-history-footer-background: #F6F6F7;
  --worktimes-history-color-check: var(--app-text-color);
  --worktimes-history-color-deviation: #0C93FF;
  --worktimes-history-color-service: #FF7200;
}

.check-card-content {
  --check-card-background-top: #fff;
  --check-card-background-bottom: #F6F6F7;
  --check-card-inside-border-color: 1px solid #DDDDDD;
  --check-card-padding-favorite-icon: 5px;
}


app-checkbox {
  input {
    display: none;
  }
  .horizontal label {
      display: inline-block!important;
  }
  label {
    display: flex;
    align-items: center;
    padding: var(--app-padding);
    opacity: 1;
    transition: opacity 0.1s ease-in-out;

    &.disabled {
      opacity: 0.5;
    }

    &.selected {
      .checkbox-icon {
        i {
          opacity: 1;
        }
      }
    }

    .checkbox-icon {
      i {
        transition: opacity 0.1s ease-in-out;
        opacity: 0;
      }
    }
  }
}


.list-nested-names {
  margin: 0;
  padding: 0;
  list-style: none;

  li {
    display: inline;
    color: var(--app-text-medium);
    // to avoid new size bug we let max-width: 100% but it could probably be remove
    max-width: 100%;
    vertical-align: top;
    margin: 0 4px 0 0;

    &:before {
      content: '/';
      font-weight: bold;
      margin: 0 4px 0 0;
    }

    &:first-child {
      &:before {
        display: none;
      }
    }

    &:last-child {
      color: var(--app-text-color);
      margin: 0;
    }

    .row-break {
      white-space: break-spaces;
    }
  }
}

.list-empty-state {
  text-align: center;
  color: #9bb3b6;

  i {
    font-size: 100px;
    margin-bottom: 10px;
    color: #9fb2b5;
  }
}

mention-list {
  left: 10px !important;
  right: 10px !important;

  .dropdown-menu {
    z-index: 100;
    position: absolute;
    top: 10px;
    left: 0;
    right: 0;
    overflow-y: auto;

    list-style: none;
    margin: 0;
    padding: 0;
    background-color: #fff;
    border: 1px solid var(--app-color-light);
    border-radius: 8px;

    &.drop-up {
      top: initial;
      bottom: 25px;
    }

    li {
      border-bottom: 1px solid var(--app-color-light);
      padding: var(--app-padding);

      &:last-child {
        border-bottom: none;
      }
    }

    .mention-item {
      font-size: var(--app-text-size-s);
      line-height: normal;
      position: relative;
      padding-left: 20px;

      .mention-item-icon-wrapper {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        text-align: center;
        width: 16px;
      }

      span {
        color: var(--app-color-light-shade);
      }
    }
  }

}

.list-margins {
  > div {
    margin-bottom: var(--app-padding);

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.tags-list {
  overflow: hidden;
  margin-bottom: -5px;

  app-tag {
    float: left;
    margin: 0 10px 5px 0
  }
}

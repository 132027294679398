app-segment-button {
  display: block;
  flex-direction: column;
  flex-wrap: nowrap;
  flex: 1 0 auto;
  height: 100%;
  line-height: 29px;
  border: 1px solid var(--app-segment-button-active);

  &:first-child {
    border-bottom-left-radius: 30px;
    border-top-left-radius: 30px;

  }
  &:last-child {
    border-left: none;
    border-bottom-right-radius: 30px;
    border-top-right-radius: 30px;
  }

  &[checked] {
    background-color: var(--app-segment-button-active);
    color: var(--app-text-color-light);
  }

  &[disabled] {
    background-color: var(--app-color-light);
    color: var(--app-color-light-shade);
    border: none;
  }

  button, a {
    display: inline-block;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    background: transparent;
    font-size: var(--app-text-size-m);
  }

  .badge {
    // properly align spinners vertically
    &:after {
      content: '';
      display: inline-block;
    }
  }
}

.subtabs-switcher-container {
  app-segment-button {
    &[checked] {
      .badge {
        background-color: var(--app-background-color);
        color: var(--app-text-color);

        ion-spinner {
          color: var(--app-text-color);
        }
      }
    }
  }
}
